<template>
    <div class="admin-attendance-wrapper">
        <AdminAttendanceReportFilter
            :filter-employees="employeeList"
            style="margin-bottom: 20px"
            @status-changed="handleOnStatusChanged"
            @user-changed="handleOnEmployeeChanged"
            @month-changed="handleMonthFilter"
            @export-csv="handleExportCsv"
        />
        <AdminAttendanceReportList
            :attendance-logs-list="attendanceLogsList"
            :is-loading="isLoading"
            :total-count="totalCount"
            :current-page="currentPage"
            :total-per-page="limit"
            @reload="handleReload"
            @on-page-change="handleOnPageChanged"
        />
    </div>
</template>

<script>
import AdminAttendanceReportFilter from '@/components/Attendance/Admin/Report/AdminAttendanceReportFilter.vue';
import AdminAttendanceReportList from '@/components/Attendance/Admin/Report/AdminAttendanceReportList.vue';
import { getPaginatedAttendanceReports } from '@/services/attendance-reports.service';

export default {
    components: {
        AdminAttendanceReportList,
        AdminAttendanceReportFilter,
    },
    data() {
        return {
            isLoading: false,
            attendanceLogsList: [{ data: 'qweqwe' }],
            selectedStatus: this.$route.query.status,
            selectedDates: null,
            selectedUser: this.$route.query.user,
            filter: {},
            limit: 10,
            currentPage: 1,
            totalCount: null,
            isFilterClick: false,
            totalPerPage: {},
            dates: [null, null],
            employeeList: [],
            options: null,
            flagBack: 0,
            flagForward: 0,
        };
    },
    created() {
        this.handleGetAllAttendance();
    },
    // mounted() {
    //   this.handleGetTimelogs()
    //   // this.handleGetProjectMembersList()
    //   this.handleGetProjectsList()
    // },

    methods: {
        handleGetAllAttendance() {
            this.isLoading = true;
            this.employeeList = [];

            getPaginatedAttendanceReports(this.filter, this.currentPage, this.limit)
                .then((res) => {
                    this.attendanceLogsList = res.data.data;
                    this.totalCount = res.data.totalCount;
                    this.isLoading = false;
                })
                .catch(() => {
                    this.$Message.error('Failed to get users');
                    this.isLoading = false;
                });
        },
        handleMonthFilter(month) {
            console.log('month filter: ', month);
            // this.filter = { createdAt: { $gte: month.from, $lte: month.to } };
            if (month) {
                this.filter = { date: { $gte: month.from, $lte: month.to } };
            } else {
                this.filter = {};
            }
            this.handleGetAllAttendance();
        },
        handleOnPageChanged(page) {
            this.currentPage = page;
            getPaginatedAttendanceReports(this.filter, this.currentPage, this.limit)
                .then((res) => {
                    this.attendanceLogsList = res.data.data;
                    this.totalCount = res.data.totalCount;
                    this.isLoading = false;
                })
                .catch(() => {
                    this.$Message.error('Failed to get users');
                    this.isLoading = false;
                });
        },
    },
};
</script>
<style lang="scss">
.admin-attendance-wrapper {
    .title-wrapper {
        display: block;

        .ivu-col {
            max-width: 100% !important;
        }
        .text-right {
            text-align: left;
            padding-top: 0 !important;
        }
    }
}
</style>
