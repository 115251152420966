import { axiosInstance, getAuthorizationHeaders } from './base';

export function getPaginatedAttendanceReports(filter, page = 1, limit = 1000) {
    const urlString = `/admin/attendancelogs/report/get-paginated-aggregated-attendance-records`;
    return axiosInstance.get(urlString, {
        params: { filter: filter, limit: limit, page: page, sort: { createdAt: -1 } },
        ...getAuthorizationHeaders(),
    });
}

export function getPrivateAttendanceZipUrl(id) {
    const urlString = `/admin/attendancelogs/report/download-report/${id}`;
    return axiosInstance.get(urlString, {
        params: {},
        ...getAuthorizationHeaders(),
    });
}
export function downloadZipFileFromGoogle(url) {
    return axiosInstance.get(url, {
        responseType: 'blob',
        // headers: {
        //     ...getAuthorizationHeaders().headers,
        // },
    });
}
