<template>
    <div class="fullheight">
        <Tabs value="name1">
            <TabPane label="Attendance" name="name1"><AdminAttendance /></TabPane>
            <TabPane label="Report" name="name2"><AdminAttendanceReportIndex /></TabPane>
        </Tabs>
    </div>
</template>

<script>
import AdminAttendance from '@/views/AdminAttendance.vue';
import AdminAttendanceReportIndex from '@/components/Attendance/Admin/Report/AdminAttendanceReportIndex.vue';
export default {
    components: {
        AdminAttendanceReportIndex,
        AdminAttendance,
    },
    data() {
        return {
            isCollapsed: false,
        };
    },
    computed: {
        rotateIcon() {
            return ['menu-icon', this.isCollapsed ? 'rotate-icon' : ''];
        },
        menuitemClasses() {
            return ['menu-item', this.isCollapsed ? 'collapsed-menu' : ''];
        },
    },
    methods: {
        handleOpenSider() {
            this.$refs['AppSider'].toggleCollapse();
        },
        handleSiderCollapsed(val) {
            this.isCollapsed = val;
        },
    },
};
</script>

<style lang="scss" scoped>
.app-content-wrapper {
    height: calc(100% - 64px);
}
.layout-header-bar {
    background: #fff;
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);
}
.layout-logo-left {
    width: 90%;
    height: 30px;
    background: #5b6270;
    border-radius: 3px;
    margin: 15px auto;
}
</style>
