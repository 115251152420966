<template>
    <div>
        <ViewAttendance ref="ViewAttendance" @reload="handleReloadList" />

        <Table
            :columns="tblColumns"
            :data="attendanceLogsList"
            size="small"
            :loading="isLoading"
            class="attendanceList"
        />

        <Page
            :total="totalCount"
            :current="currentPage"
            :page-size="totalPerPage"
            class="pagination"
            @on-change="handleOnpageChanged"
        />
    </div>
</template>

<script>
import moment from 'moment';
import ViewAttendance from '@/components/Attendance/Dev/ViewAttendance';
import Smile from '@/assets/images/smile.svg';

import { getPrivateAttendanceZipUrl } from '@/services/attendance-reports.service';

export default {
    components: { ViewAttendance },
    props: {
        isLoading: {
            type: Boolean,
            default: false,
        },
        attendanceLogsList: {
            type: Array,
            default: () => [],
        },
        currentPage: {
            type: Number,
            default: 0,
        },
        totalCount: {
            type: Number,
            default: 0,
        },
        totalPerPage: {
            type: Number,
            default: 5,
        },
    },

    data() {
        return {
            viewLoading: false,
            tblColumns: [
                {
                    title: 'Date',
                    key: 'createdAt',
                    width: 200,
                    render: (h, params) => {
                        return h(
                            'div',
                            params.row.createdAt ? moment(params.row.createdAt).utc().format('YYYY-MM-DD') : ''
                        );
                    },
                },
                {
                    title: 'Date From',
                    key: 'dateFrom',
                    //fixed: 'left',
                    width: 200,
                    render: (h, params) => {
                        return h('div', params.row.dateFrom ? moment(params.row.dateFrom).format('YYYY-MM-DD') : '');
                    },
                },
                {
                    title: 'Date To',
                    key: 'dateTo',
                    //fixed: 'left',
                    width: 200,
                    render: (h, params) => {
                        return h('div', params.row.dateTo ? moment(params.row.dateTo).format('YYYY-MM-DD') : '');
                    },
                },
                {
                    title: 'Path',
                    key: 'filePath',
                    //fixed: 'left',
                    width: 200,
                    render: (h, params) => {
                        return h('div', params.row.filePath);
                    },
                },
                {
                    title: 'Actions',
                    width: 200,
                    align: 'center',
                    render: (h, params) => {
                        return h('div', [
                            h('Tooltip', { props: { placement: 'top', content: 'View' } }, [
                                h(
                                    'Button',
                                    { class: 'pt-btn-3', props: { loading: this.viewLoading } },
                                    [
                                        h('Icon', {
                                            props: {
                                                type: 'md-eye',
                                                size: '25',
                                                loading: true,
                                            },
                                            on: {
                                                click: () => {
                                                    this.downloadZipFile(params.row);
                                                },
                                            },
                                        }),
                                    ],
                                    'view'
                                ),
                            ]),
                        ]);
                    },
                },
            ],
            tblData: [],
            profileImg: Smile,
        };
    },
    watch: {
        attendanceLogsList(data) {
            this.tblData = data;
        },
    },
    methods: {
        handleReloadList() {
            this.$emit('reload');
        },
        handleOnpageChanged(page) {
            this.$emit('on-page-change', page);
        },
        async downloadZipFile(data) {
            this.viewLoading = true;
            data.isViewLoading = true;

            console.log('downloading zip file: ', data);
            const { data: file } = await getPrivateAttendanceZipUrl(data._id);
            console.log('file: ', file);
            this.viewLoading = false;
            this.$nextTick(() => {
                window.open(file.url, '_blank').focus();
                data.isViewLoading = false;
            });
        },
    },
};
</script>

<style lang="scss">
ul.pagination.ivu-page {
    text-align: end;
}
.adminAttendanceList button.pt-btn-3 {
    width: 25px;
}
.adminAttendanceList i.ivu-icon {
    margin-left: -12.5px;
}
.statusLabel {
    display: inline-block;
    color: #fff;
    border-radius: 5px;
    box-shadow: 0 0 5px -1px rgba(0, 0, 0, 0.2);
    vertical-align: middle;
    max-width: 250px;
    padding: 5px;
    text-align: center;
}
.success {
    background: #49e97e;
    font-weight: bold;
}
.warning {
    background: #ffc107;
    font-weight: bold;
}
.danger {
    background: #e9495d;
}
.primary {
    background: #211841;
}
.secondary {
    background: #828899;
}

.adminCollapsible {
    .collapsiblePane {
        margin-bottom: 15px;
        border-collapse: separate;
        border-spacing: 0 5px;
        .ivu-collapse-header {
            padding-top: 15px;
            line-height: 20px;
            height: 88px;
            border: 1px solid;
            border-radius: 10px;
            label {
                strong {
                    color: #463c6e;
                }
                .name {
                    width: 185px;
                }
                .div {
                    display: inline-grid;
                    margin-right: 15px;
                    span {
                        font-size: small;
                    }
                }
                .regular {
                    margin-left: 15px;
                }
                .vl {
                    border: 1px solid grey;
                    height: 87px;
                    position: absolute;
                    top: 0;
                }
                .profile {
                    margin-right: 60px;
                    img {
                        border-radius: 50%;
                        width: 50px;
                        position: absolute;
                    }
                }
            }
        }
    }
}
</style>
